// SportClub
import React from 'react';
import { SportsClub } from 'views/Archive2023';


const SportClubPage = () => {
  return <SportsClub />;
};

export default SportClubPage;
